html {
  background-color: #000;
  color: #fff;
}
  html {
    background-color: #000;
    color: #fff;
  }
  
  * {
    font-family: 'Times New Roman' !important ;
  } 
.app {
  position: relative;
  width: 100vw;
}
.header {
  text-align: center;
  width: 165px;
  margin: auto;
}
.body {

}
.logic {
  text-align: center;
  width: 100%;
  margin: auto;

}
.cells {
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: auto;
  /* background-color: red; */

  line-height: 0;
}
.cell {
  width: 2.5vw; 
  max-width: 20px;
  height: 2.5vw;
  max-height: 20px;
  display: inline-block;
  outline: 1px solid rgba(255, 255, 255, 0.35);
  @media (max-width: 500px) {
    outline:1px solid rgba(255, 255, 255, 0.1);
  }
}

.cell img {
  width: 2.5vw; 
  max-width: 20px;
  height: 2.5vw;
  max-height: 20px;
}

.selected {
  outline: 2px solid rgba(255, 0, 0, 1);
}

p img {
  width: 100px;
}

.addr {
  text-decoration: underline;
  cursor: pointer;
}

.addr_text {
  position: absolute;
  display: none;
  margin: 0 0 0 10px 
  /* margin: auto; */
}

.addr:hover + .addr_text{
  display: inline-block;
}

.update_text {
  position: absolute;
  display: none;
  /* margin: 0 0 0 10px  */
  /* margin: auto; */
}

.update:hover + .update_text{
  display: inline-block;
}

.mint_text {
  position: absolute;
  display: none;
  /* margin: 0 0 0 10px  */
  /* margin: auto; */
}

.mint:hover + .mint_text{
  display: inline-block;
}

  .tc-root {
    left: 0 !important;
    right: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(0%);
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  #ton-connect-button {
    > div {
      > tc-root{
        > div {
          border: none !important;
        }
      }
    }
    :hover {
      button {
        text-decoration: underline;
        border-radius: 0;
        border: 1px solid #fff;
      }
    }
    button {
      border-radius: 0;
      background-color: #000;
      border: 1px dashed #fff;
    }
  }
  
  #tc-widget-root {
    tc-root {
      display:contents;
    }
  }
  
  a {
    color: white
  }
